import React, { useEffect, useState } from "react";
import { menuItems } from "../../constant/constant";
import TableComponent from "./TableComponent";
import MobileCardComponent from "./MobileCardComponent";



export const PositionComponent = () => {
  const [activeMenu, setActiveMenu] = useState("Positions");
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 448)
  const [data, setData] = useState([]);

  console.log("data");
  console.log(data);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 448);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await window?.api?.shoonyaApi.get_positions();
        // console.log("response from position api", response);
        if (response) {
          setData(response.data);
        }
      } catch (error) {
        console.log("error in fetching position data", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="">

      {/* top content */}
      <div className="flex justify-between bg-skin-cardbgColor text-skin-textColor items-center bg-[#fff] rounded-t-md py-3 mt-4 md:px-6">
        <p className="font-medium text-lg ml-1">
          Net Qty: <span className="font-normal">0</span>
        </p>

        <p className="font-medium text-lg ml-1">
          MTM : <span className="font-normal text-green-600">3, 202.75</span>
        </p>
      </div>

      <div className="bg-skin-cardbgColor text-skin-textColor rounded-b-md md:px-6 py-3 mb-4 ">
        {
          isSmallScreen ? (
            <MobileCardComponent data={data} showHeaders={['exch', 'tsym', 'netqty', 'rpnl']}/> 
          ) : (
            <TableComponent TableData={data} showHeaders={['exch', 'tsym', 'netqty', 'rpnl']} />
          )
        }
      </div>
    </div>
  );
};
