import sound from "./eatpellet.mp3"

export const getDate = () => {
    const currentDate = new Date();

    // Define options for formatting the date
    const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };

    // Format the current date
    return currentDate.toLocaleString('en-US', options);
}

const playSound = () => {
    try {
        const audio = new Audio(sound);
        audio.play();
    } catch (error) {
        console.error('Error playing sound:', error);
    }
}

export { playSound }