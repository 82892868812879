// copilot create a anvaigation component for the dashboard
// that will be used to navigate to the different sections of the dashboard
import React from 'react';
import { Link } from 'react-router-dom';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard';
import { ShoonyaWrapper } from '../utils/ShoonyaApi-wrapper';

const DashboardPage = () => {

    return (
        <Dashboard />
    );
};

export default DashboardPage;
