import React, { useEffect, useState } from "react";
/* global chrome */
import {
  AiOutlineArrowDown,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineRight,
} from "react-icons/ai";
import { RxButton } from "react-icons/rx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CallstrikePrices,
  Exchanges,
  expiryDates,
  orderTypes,
  putStrikePrices,
  quantity,
} from "../constant/constant";
import { getDate } from "../constant/utils";
import { ShoonyaWebSocket, ShoonyaWrapper } from "../utils/ShoonyaApi-wrapper";
import OptionChainList from "./TabsContent/OptionChainList";
import TableComponent from "./TabsContent/TableComponent";


const oldSession = window.localStorage.getItem("session")
const oldSessionObj = JSON.parse(oldSession)
console.log("old session object", oldSessionObj)
if (oldSessionObj) {
  const api = new ShoonyaWrapper()
  await api.setSession(oldSessionObj)
  await api.startWebSocket(oldSessionObj?.actid, oldSessionObj?.susertoken)
  window.api = api
}
else {
  console.log("Old Session Expired")
}


export const OrderWindow = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [optionChainOpen, setIsOptionChainOpen] = useState(false)
  const [optionChain, setOptionChain] = useState([])
  const currentDate = getDate()
  const [currentSymbol, setCurrentSymbol] = useState('')
  const [selectedExchange, setSelectedExchange] = useState('')
  const [currentToken, setCurrentToken] = useState('')
  const [searchResult, setSearchResult] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [symbolExpiryDates, setExpiryDates] = useState([])
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [buyOrSell, setBuyOrSell] = useState()
  const [autoSrikeData, setautoSrikeData] = useState([])

  // console.log("buyOrSell");
  // console.log(buyOrSell);

  const autoStrike = async () => {
    const Result = await window.api.autoStrike(document.querySelector("#auto-strike").value)
    setautoSrikeData(Result)
    console.log("auto strike", autoSrikeData)
  }


  const get_option_chain_data = async () => {
    try {
      const response = await window.api.shoonyaApi.get_option_chain(selectedExchange, autoSrikeData?.[0]?.tsym, document.querySelector(`.ltp-${currentToken}`).innerHTML, "4");

      if (response) {
        setOptionChain(response.data.values);
        setIsOptionChainOpen(true);
      }
    } catch (error) {
      console.log("error in option chain", error);
    }
  };

  const closeModal = () => {
    setIsOptionChainOpen(false);
  };

  const placeOrder = async (buyOrSell, callOrPut) => {
    console.log(`${buyOrSell + callOrPut} `);
    console.log("auto strike symbol", autoSrikeData)

    var tradingSymbol = ""
    if (callOrPut === 'call') {
      console.log("call")
      tradingSymbol = autoSrikeData?.[0]?.tsym
    }
    else if (callOrPut === 'put') {
      console.log("put")
      tradingSymbol = autoSrikeData?.[1]?.tsym
    }
    console.log("trading symbol", tradingSymbol)





    let orderparams = {
      'buy_or_sell': buyOrSell === 'buy' ? 'B' : 'S',
      'product_type': document.getElementById('productType').value,
      'exchange': autoSrikeData?.[0]?.exch,
      'tradingsymbol': tradingSymbol,
      'quantity': parseInt(document.getElementById('quantity').value),
      'discloseqty': 0,
      'price_type': document.getElementById("price_type").value,
      'price': parseFloat(document.getElementById('strikePrice').value ? document.getElementById('strikePrice').value : 0),
    };
    console.log(orderparams); // Log the object to see the values
    localStorage.setItem(`orderparams${buyOrSell.charAt(0).toUpperCase() + buyOrSell.slice(1)}`, JSON.stringify(orderparams));
    setBuyOrSell(orderparams)

    try {
      const response = await window.api.shoonyaApi.place_order(orderparams);
      console.log("order response", response)

      if (response.statusText === "OK") {
        // toast(`${buyOrSell} order status ${response.data.stat} order id ${response.data.norenordno}`, {
        //   position: "top-center",
        //   autoClose: 3000,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "dark",
        // });
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast(`Error placing order. Please try again.`, {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  }


  const searchSymbol = async (symbol) => {
    // const result = await window?.api?.shoonyaApi.searchscrip(symbol);
    const result = await window?.api?.searchScrip(symbol);
    setSearchResult(result);
    setShowDropdown(!!result?.values?.length);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    searchSymbol(value);
  };

  const handleOptionClick = (value, tradingsymbol, exchange, token,quantity) => {
    setInputValue(value);
    setShowDropdown(false);
    setSelectedValue(value);
    console.log("currnet symbol selected", tradingsymbol, exchange, value)

    setCurrentSymbol(tradingsymbol)
    setSelectedExchange(exchange)
    setCurrentToken(token)
    const Result = [{
      "exch": exchange,
      "token": token,
      "tsym": tradingsymbol,
      "ls":quantity,
      "weekly": "W1",
      "dname": tradingsymbol,

    }, {
      "exch": exchange,
      "token": token,
      "tsym": tradingsymbol,
      "ls":quantity,
      "weekly": "W1",
      "dname": tradingsymbol,

    },]
    setautoSrikeData(Result)
    console.log("auto strike data set", autoSrikeData)
    window.ws.sendQuote(exchange, token)
  };

  const [loginResponse, setLoginResponse] = useState({});
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await api.login({
  //       userid: "FA76640",
  //       password: "Trade@5",
  //     });
  //     console.log("response from login api", response);

  //     window.localStorage.setItem("token", JSON.stringify(response))
  //     setLoginResponse(response);
  //     console.log("login response", loginResponse);

  //     const ws = new ShoonyaWebSocket(response.data.uid, response.data.susertoken, {
  //       onopen: () => {
  //         console.log("socket open");
  //         ws.send(JSON.stringify({ "t": "t", "k": "NSE|22#BSE|500400" }));

  //       },
  //       onclose: () => {
  //         console.log("socket close")
  //       },
  //       onerror: () => {
  //         console.log("socket error")
  //       },
  //       onmessage: (msg) => {
  //         console.log("quote", JSON.stringify(msg))

  //       }

  //     })


  //     // await api.getMTM()
  //     const expiry = await api.getAllExpiryDates("NFO", `${currentSymbol}`)
  //     console.log("expiry", expiry)
  //     setExpiryDates(expiry)
  //   };
  //   fetchData();
  // }, [currentSymbol]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjusted breakpoint to 768 for small screens
    };

    handleResize(); // Call handleResize initially to set the initial state correctly
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <>
      <ToastContainer />
      <div className="my-4 bg-skin-cardbgColor p-4 rounded-[6px]">
        <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-3 text-skin-textColor">
          {/* each input and label */}

          {/* <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Select Exchange
            </label>
            <select
              name=""
              id=""
              onChange={(e) => setCurrentSymbol(e.target.value)}
              className="w-full border-2 px-2 py-1 bg-skin-bgColor  focus:outline-none rounded-md cursor-pointer">
            </select>
          </div> */}
          <div className="relative flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Search Symbol
            </label>
            <input
              name=""
              id="symbol"
              value={inputValue}
              onChange={handleInputChange}
              className="w-full border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md"
              list="searchResults" // Associate the datalist with the input
            />
            {Array.isArray(searchResult?.values) && showDropdown && (
              <div className="absolute top-[4rem] z-[99999] w-full max-h-[200px] overflow-auto bg-skin-bgColor">
                {searchResult.values.map((result) => (
                  <div
                    key={result.token}
                    onClick={() =>
                      handleOptionClick(
                        `${result.cname ? result.cname : result.dname} (${result.exch})`, result.tsym, result.exch, result.token,result.ls
                      )
                    }
                    className="cursor-pointer px-2 py-1 hover:bg-[#3d8bd4]"
                  >
                    {`${result.cname ? result.cname : result.dname} (${result.exch})`}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Price ( for limit orders )
            </label>
            <input
              id="strikePrice"
              type="text"
              className="w-full border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md" />
          </div>
          <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Qty (Lots)
            </label>
            <input
              id="quantity"
              type="text"
              className="w-full border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md"
              value={autoSrikeData?.[0]?.ls }  />
          </div>
          <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Product{" "}
            </label>
            <select
              name=""
              id="productType"
              className="w-full border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md cursor-pointer">
              <option value={"C"}>CNC </option>
              <option value={"I"}>MIS</option>
              <option value={"I"}>NRML</option>
              <option value={"I"}>CO</option>
              <option value={"I"}>BO</option>
            </select>
          </div>
          <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Order Type{" "}
            </label>
            <select
              name=""
              id="price_type"
              className="w-full border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md cursor-pointer">
              {orderTypes?.map((order) => (
                <option value={order} key={order}>
                  {order}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px] ">
              SL Points
            </label>
            <div className=" border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md cursor-pointer flex gap-2 items-center">
              <input type="checkbox" id="slPointsChecked" className="" />
              <input type="number" id="slPoints" className="w-full px-3  focus:outline-none rounded-md" />
            </div>
          </div>
          <div className="relative flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px] ">
              Option Chain
            </label>
            <div className=" border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md cursor-pointer flex gap-2 items-center">
              <button onClick={() => { get_option_chain_data() }}>
                Option Chain
              </button>
            </div>

            {
              optionChainOpen && (
                <OptionChainList data={optionChain} showHeaders={['optt', 'strprc', 'optt']} closeModal={closeModal} />
              )
            }
          </div>
          <div className="flex flex-col gap-2 w-full col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px] ">
              Auto Strike
            </label>
            <div className=" border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md cursor-pointer flex gap-2 items-center">
              <select id='auto-strike' onChange={() => { autoStrike() }}>
                <option value="Nifty 50">Nifty </option>
                <option value="BankNifty">BankNifty</option>
                <option value="FinNifty">FinNifty</option>
              </select>
            </div>
          </div>

          {/* <div className="flex flex-col gap-2 w-full col-span-2 md:col-span-1 text-[12px] md:text-[14px]">
            <label className="font-normal tracking-[.5px]">
              Expiry Date
            </label>
            <select
              name=""
              id=""
              className="border-2 px-2 py-1 bg-skin-bgColor focus:outline-none rounded-md cursor-pointer">
              {symbolExpiryDates?.map((expiry) => (
                // console.log("expiry", expiry.exd)
                <option value={expiry.tsym}>
                  {expiry.dname}
                </option>
              ))}
            </select>
          </div> */}
        </div>
      </div >

      <div className="flex flex-col gap-6 my-8  bg-skin-cardbgColor p-4 rounded-[6px] overflow-auto">
        {/* LTP and BUY CE AND BUY PE buttons */}
        <div className="flex gap-2 xl:gap-18  xl:flex-row sm:justify-between items-end md:items-center  text-skin-textColor">
          {/* stirke price CE */}
          <div className="w-full">
            <p className="text-[10px] md:text-[15px] mb-1 font-normal tracking-normal">
              Strike : {autoSrikeData?.[0]?.dname}
            </p>
            <span className={"text-[14px] md:text-[16px] font-bold mb-2" + `ltp-${autoSrikeData?.[0]?.token}`}>LTP: 357</span> <span className={`pc-${autoSrikeData?.[0]?.token}`}>0.54</span>% <br />
            <div className="flex gap-1 items-center mt-2 text-right mb-[-7px]">
              <button className="bg-red-500 flex-grow flex items-center justify-center gap-0.5 md:gap-1 text-[0.6rem] md:text-[1rem] text-white px-1 py-2 rounded-[3px] border-none outline-none capitalize" onClick={() => placeOrder('sell', 'call')}>
                <AiOutlineArrowLeft size={14} /> <span>sell call</span>
              </button>

              <button className="bg-green-500 flex-grow flex items-center justify-center gap-0.5 md:gap-1 text-[0.6rem] md:text-[1rem] text-white px-1 py-2 rounded-[3px] border-none outline-none capitalize" onClick={() => placeOrder('buy', 'call')}>
                <AiOutlineArrowUp size={14} /> <span>buy call</span>
              </button>
            </div>
          </div>

          {/* center component */}
          {
            isSmallScreen ? "" : (
              <div className="w-full">
                <h3 className="text-lg font-medium sm:text-center mb-2">{autoSrikeData?.[0]?.dname || "No Scrip Selected"}</h3>
                <p className="mb-2 sm:text-center">
                  LTP: <span className={`ltp-${currentToken} to-green-500`}> 44030.80 </span>  <span className={`pc-${currentToken}`}>0.54</span>%
                </p>
                <div className="flex flex-col md:flex-row gap-4 text-[0.9rem]">
                  <button className="border border-red-400 flex-grow text-red-400 px-1 py-2 rounded-md" onClick={() => { window.api.clearAllPositions() }}>
                    Close All Positions
                  </button>
                  <button className="border border-red-400 flex-grow text-red-400 px-1 py-2 rounded-md" onClick={() => { window.api.cancelAllOrders() }}>
                    Cancel All orders
                  </button>
                </div>
              </div>
            )
          }

          <div className="w-full">
            <p className="text-[10px] md:text-[15px] mb-1 font-normal tracking-normal">
              Strike : {autoSrikeData?.[1]?.dname}
            </p>
            <span className={"text-[14px] md:text-[16px] font-bold mb-2 " + `ltp-${autoSrikeData?.[1]?.token}`}>LTP: 368.57  </span> <span className={`pc-${autoSrikeData?.[1]?.token}`}>0.54</span>% <br />
            <div className="flex gap-2 items-center mt-2 text-right mb-[-7px]">
              <button className="bg-green-500 flex-grow flex gap-0.5 md:gap-1 text-[0.6rem] md:text-[1rem] items-center justify-center text-white px-1 py-2 rounded-[3px] border-none outline-none capitalize" onClick={() => placeOrder('buy', 'put')}>
                <AiOutlineArrowDown size={14} /> Buy Put
              </button>
              <button className="bg-red-500 flex-grow flex gap-0.5 md:gap-1 text-[0.6rem] md:text-[1rem] items-center justify-center text-white px-1 py-2 rounded-[3px] border-none outline-none capitalize" onClick={() => placeOrder('sell', 'put')}>
                <AiOutlineArrowRight size={14} /> Sell Put
              </button>
            </div>
          </div>
        </div>

        {/* center component */}
        {
          isSmallScreen && (
            <div className="w-full">
              <p className="mb-2 text-center text-[0.9rem]">
                LTP: <span className={`ltp-${currentToken}`}> 44030.80 </span>  <span className={`pc-${currentToken}`}>(0.54)</span>%
              </p>
              <div className="flex flex-row gap-4 text-[0.7rem] md:text-[0.9rem]">
                <button className="border border-red-400 flex-grow text-red-400 px-1 py-1 rounded-md" onClick={() => { window.api.clearAllPositions() }}>
                  Close All Positions
                </button>
                <button className="border border-red-400 flex-grow text-red-400 px-1 py-1 rounded-md" onClick={() => { window.api.cancelAllOrders() }}>
                  Cancel All orders
                </button>
              </div>
            </div>
          )
        }
      </div >
    </>
  );
};
