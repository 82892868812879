// copilot create a anvaigation component for the dashboard
// that will be used to navigate to the different sections of the dashboard
import { Tab } from '@headlessui/react';
import React, { useEffect, useState } from "react";
import { FaTable } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { menuItems } from "../constant/constant";
import { Menu } from "./Menu/Menu";
import OrderBook from "./OrderBook";
import OrderForm from "./OrderForm";
import { OrderWindow } from "./OrderWindow";
import { FundsComponent } from "./TabsContent/FundsComponent";
import { OrderBookComponent } from "./TabsContent/OrderBookComponent";
import { PositionComponent } from "./TabsContent/PositionComponent";
import { TradeBookComponent } from "./TabsContent/TradeBookComponent";
import TradeHistory from "./TradeBook";
import ThemeToggleBtn from './ThemeToggleBtn';


const Dashboard = () => {
  const [selectedPair, setSelectedPair] = useState("BTC/USD");
  const [orderBook, setOrderBook] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [userOrders, setUserOrders] = useState([]);
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("");
  const [activeBigMenu, setActiveBigMenu] = useState("Positions");
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [data, setData] = useState([]);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 448);
    };

    handleResize(); // Call handleResize initially to set the initial state correctly
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);


  const handleMobileNav = () => {
    setIsNavOpen(!isNavOpen);
    document.body.style.overflow = isNavOpen ? "unset" : "hidden";
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await window?.api?.shoonyaApi.get_positions();
        // console.log("response from position api", response);
        if (response) {
          setData(response);
        }
      } catch (error) {
        console.log("error in fetching position data", error);
      }
    };
    fetchData();
  }, []);


  // Fetch order book and trade history data from backend
  useEffect(() => {
    // Fetch order book data
    // Fetch trade history data
  }, [selectedPair]);

  // Event handler for placing orders
  const placeOrder = (order) => {
    // Send order to backend for execution
    // Update userOrders state with new order
  };

  const handleLogout = () => {
    localStorage.removeItem('session');
    
    if (localStorage.session) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }

  return (
    <div className="bg-skin-bgColor h-[100vh] md:h-full">
      <div className='relative w-full max-w-[1280px]  p-3 lg:px-6 mx-auto  mb-16 md:mb-0'>
        <div className="flex items-center justify-between px-1">
          <ThemeToggleBtn />

          <button className='text-skin-textColor bg-skin-togglebg hover:bg-transparent duration-100 border border-toggleBorder px-2.5 py-1 rounded-[5px]' onClick={handleLogout}>logout</button>
        </div>


        {/* order window */}
        {
          isSmallScreen ? (
            <>
              {activeMenu === "" && <OrderWindow />}
            </>
          ) : (
            <OrderWindow />
          )
        }



        {isSmallScreen ? (
          <Tab.Group>
            <Tab.List>

              <div className="fixed bg-skin-cardbgColor text-skin-textColor bottom-0 left-0 w-full py-3 px-4 flex justify-center bg-[#474747]">
                <span onClick={handleMobileNav} className="text-[1.6rem]"><FaTable /></span>
              </div>

              {
                isNavOpen && (
                  <div className="fixed z-[9999999] top-0 left-0 w-full flex">
                    <div className="relative  bg-skin-bgColor text-skin-textColor w-[40%] py-2 px-4 flex flex-col items-center justify-center gap-4">
                      {menuItems?.map((item) => (
                        <Tab
                          key={item.id}
                          onClick={() => {
                            setActiveMenu(item.title);
                            handleMobileNav();
                          }}
                          className={`px-6 py-1.5 cursor-pointer transition-all whitespace-nowrap duration-100 ${activeMenu === item.title
                            ? "  bg-skin-cardbgColor text-skin-textColor  rounded-[4px]"
                            : null
                            }`}>
                          {item.title}
                        </Tab>
                      ))}

                      <span onClick={handleMobileNav} className="absolute top-5 right-6 text-[1.4rem] ">
                        <RxCross2 />
                      </span>
                    </div>

                    <div className="bg-[#00000033] w-full h-[100vh]"></div>
                  </div>
                )
              }
            </Tab.List>

            <Tab.Panels className="text-skin-textColor">
              {activeMenu !== "" && (
                <div className='bg-skin-cardbgColor text-skin-textColor rounded-b-[12px] px-6 py-3 mb-4
                mt-4 '>
                  <Tab.Panel>
                    <button onClick={() => setActiveMenu("")}>Back</button>
                    <PositionComponent /></Tab.Panel>
                  <Tab.Panel>
                    <button onClick={() => setActiveMenu("")}>Back</button>
                    <OrderBookComponent /></Tab.Panel>
                  <Tab.Panel>
                    <button onClick={() => setActiveMenu("")}>Back</button>
                    <TradeBookComponent /></Tab.Panel>
                  <Tab.Panel>
                    <button onClick={() => setActiveMenu("")}>Back</button>
                    <FundsComponent /></Tab.Panel>
                </div>
              )}
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <Tab.Group>
            <Tab.List>
              <div className="w-full flex">
                <div className="relative bg-skin-cardbgColor text-skin-textColor w-full py-2 px-4 flex items-center gap-4 overflow-auto rounded-t-md">
                  {menuItems?.map((item) => (
                    <Tab
                      key={item.id}
                      onClick={() => {
                        setActiveMenu(item.title);
                        handleMobileNav();
                      }}
                      className={`px-6 py-1.5 cursor-pointer transition-all whitespace-nowrap duration-100 ${activeMenu === item.title
                        ? "  bg-skin-tabBgColor text-skin-tabTextColor rounded-[4px]"
                        : null
                        }`}>
                      {item.title}
                    </Tab>
                  ))}
                </div>
              </div>
            </Tab.List>

            <Tab.Panels>
              {activeBigMenu !== "" && (
                <>
                  <Tab.Panel>
                    <PositionComponent />
                  </Tab.Panel>
                  <Tab.Panel>
                    <OrderBookComponent />
                  </Tab.Panel>
                  <Tab.Panel>
                    <TradeBookComponent />
                  </Tab.Panel>
                  <Tab.Panel>
                    <FundsComponent />
                  </Tab.Panel>
                </>
              )}
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>


    </div >

  );
};

export default Dashboard;
