import React, { useState } from "react";
import { ShoonyaWrapper } from "../utils/ShoonyaApi-wrapper";
import { useNavigate } from "react-router";
import { AiFillEyeInvisible, AiOutlineEye } from "react-icons/ai";
import bgImg from "../assets/background-img.jpg";

function LoginPage() {
  const navigate = useNavigate();
  const api = new ShoonyaWrapper();
  window.api = api

  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);

  const handleLogin = async () => {
    const response = await api.login({
      userid: userID,
      password: password,
    });

    const loginToken = {
      token: response?.data?.susertoken,
      uid: response?.data?.uid
    }
    // console.log("response from login api", response);
    const searchResponse = await api.searchScrip("Adani Power")
    // console.log("search response", searchResponse)

    window.localStorage.setItem("token", JSON.stringify(loginToken));

    if (response.status === "OK") {
      navigate("/dashboard");
    } else {
      alert("Login Failed");
    }
  };

  return (
    <div className="">
      <div
        className="bg-[#4D4C4F] p-8 h-screen overflow-hidden ">
        <div className="background-img w-full h-full flex items-center px-6">
          <div className="text-[#A6A5A9] bg-[#18181A33] px-8 py-12  w-full max-w-[380px] mx-auto  rounded-[14px] shadow-[0px_22px_90px_-50px_#FFF]">
            <h1 className=" text-center text-[#E1E1E1] pb-6 capitalize font-semibold text-[1.8rem]">Login account</h1>

            <div className="flex flex-col gap-2">
              <label className="text-[#ccc]">User ID</label>
              <input
                className="mb-4  py-2 px-4 bg-[#E1E1E1] text-[#454545] placeholder:text-[#45454566] text-[0.9rem] capitalize rounded-[5px]"
                type="email"
                value={userID}
                onChange={(e) => setUserID(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-2 relative">
              <label className="text-[#ccc]">Password</label>
              <input
                className="mb-4 border-2 border-gray-500 py-2 px-4 rounded-lg"
                type={hidden ? "password" : "text"}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              {hidden ? (
                <AiOutlineEye
                  className="absolute right-4 top-10 cursor-pointer"
                  size={24}
                  onClick={() => setHidden(!hidden)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="absolute right-4 top-10 cursor-pointer"
                  size={24}
                  onClick={() => setHidden(!hidden)}
                />
              )}
            </div>

            <div className="flex gap-2 items-center mb-4 ml-2">
              <input
                type="checkbox"
                name="flexCheck"
                value=""
                id="flexCheckDefault"
                label="Remember me"
                className="cursor-pointer"
              />
              <a href="!#" className="text-[0.8rem] text-[#ccc]">Forgot password ?</a>
            </div>

            <button
              className="w-full bg-[#5C81FF] text-center rounded-lg text-white py-3 px-4"
              size="lg"
              onClick={handleLogin}>
              Sign in
            </button>
          </div>
        </div>

        {/* <div className="w-[900px]">
          <img src={loginImg} alt="" />
        </div> */}

      </div>
    </div>
  );
}

export default LoginPage;
