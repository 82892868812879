import logo from './logo.svg';
import './App.css';
import LoginPage from './pages/loginPage';
import AppRouters from './routers/AppRouters';
import { useContext } from 'react';
import { ThemeContext } from './context/ThemeContext';

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={theme ? "" : "light-theme"}>
      <AppRouters />
    </div>
  );
}

export default App;
