import React from 'react'
import { RxCross1 } from "react-icons/rx";

const OptionChainList = ({ data, showHeaders, closeModal }) => {
  showHeaders = new Set(showHeaders)

  // Separate CE and PE values
  const ceData = data.filter(item => item.optt === 'CE');
  const peData = data.filter(item => item.optt === 'PE');

  return (
    (data?.length > 0) ?
      (
        <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2'>
          <div className='fixed z-[1000000] inset-0 bg-[#fff] bg-opacity-25 backdrop-blur-sm flex items-center justify-center'>
            <span className='cursor-pointer absolute z-50 top-12 text-[1.25rem]' onClick={closeModal}><RxCross1 /></span>
            <div className="relative optionCHainScroll overflow-y-auto max-h-[350px] max-w-[350px] w-full rounded-md border border-[#aaa] bg-skin-cardbgColor">
              <table className="table-auto min-w-full border-collapse overflow-y-auto h-[350px]">
                <thead className='sticky'>
                  <tr className="">
                    {showHeaders.has('optt') && (
                      <>
                        <th className="p-3 text-nowrap text-[0.9rem] font-medium text-center capitalize text-skin-textColor bg-skin-bgColor">optt</th>
                      </>
                    )}
                    {showHeaders.has('strprc') && (
                      <th className="p-3 text-nowrap text-[0.9rem] font-medium text-center capitalize text-skin-textColor bg-skin-bgColor">strprc</th>
                    )}
                    {showHeaders.has('optt') && (
                      <>
                        <th className="p-3 text-nowrap text-[0.9rem] font-medium text-center capitalize text-skin-textColor bg-skin-bgColor">optt</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {ceData.map((ceRow, ceIndex) => (
                    <tr key={`ce-${ceIndex}`}>
                      {showHeaders.has('optt') && (
                        <>
                          <td className="px-4 py-2 font-normal text-center text-[13px]">{ceRow.optt}</td>
                          <td className="px-4 py-2 font-normal text-center text-[13px]">{ceRow.strprc}</td>
                          <td className="px-4 py-2 font-normal text-center text-[13px]"></td>
                        </>
                      )}
                    </tr>
                  ))}
                  {peData.map((peRow, peIndex) => (
                    <tr key={`pe-${peIndex}`}>
                      {showHeaders.has('optt') && (
                        <>
                          <td className="px-4 py-2 font-normal text-center text-[13px]"></td>
                          <td className="px-4 py-2 font-normal text-center text-[13px]">{peRow.strprc}</td>
                          <td className="px-4 py-2 font-normal text-center text-[13px]">{peRow.optt}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : <div className='text-skin-textColor py-8 text-center'> No Data Found </div>
  )
}

export default OptionChainList