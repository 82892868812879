import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { playSound } from "../../constant/utils"
import ShoonyaApi from "../ShoonyaApi-js/app";
const BASE_URL = process.env.NODE_ENV === "production" ? "https://kredkonnect-backend.onrender.com" : "https://kredkonnect-backend.onrender.com"

class ShoonyaWrapper {
    constructor() {
        this.shoonyaApi = ShoonyaApi
        this.uid = ""
    }

    async login(authparams) {
        this.uid = authparams.userid
        var response = await fetch(`${BASE_URL}/shoonya/v1/sessionGenerate`, { "method": "POST", "headers": { "Content-Type": "application/json" }, "body": JSON.stringify(authparams) })
        response = await response.json()

        await this.setSession({ susertoken: response?.data?.susertoken, actid: response?.data?.uid })
        window.localStorage.setItem("session",JSON.stringify({ susertoken: response?.data?.susertoken, actid: response?.data?.uid }))
        await this.startWebSocket(response?.data?.uid, response?.data?.susertoken)
         // make websocket global 
        return response

    }

    async setSession(response) {
        this.shoonyaApi.setSessionDetails(response)
        return this.shoonyaApi
    }

    async startWebSocket(uid,susertoken) { 
        const ws = new ShoonyaWebSocket(uid, susertoken, {
            onopen: () => {
                // console.log("socket open");
                ws.sendQuote("NSE", "22")

            },
            onclose: () => {
                console.log("socket close")
            },
            onerror: () => {
                console.log("socket error")
            },
            onmessage: (msg) => {
                console.log("quote", JSON.stringify(msg))
                const Ltpelement = document.querySelector(`.ltp-${msg.tk}`)
                if (Ltpelement && msg?.lp) {
                    Ltpelement.innerHTML = msg?.lp
                    document.querySelector(`.pc-${msg.tk}`).innerHTML = msg?.pc

                }

                if (msg.t === "om") {
                    // order updates
                    console.log("order message", msg)
                    toast.success(`Order for ${msg?.tsym} is ${msg?.status} ${msg?.rejreason || ""} `, {
                        position: "top-center",
                        autoClose: 3000,
                        onOpen: playSound,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "dark",
                    })
                }

            }

        })
        window.ws = ws

    }

    async getAllExpiryDates(exchange, searchSymbol) {
        try {
            const searchResult = await this.shoonyaApi.searchscrip(exchange, searchSymbol);
            // console.log("searchResult", searchResult)
            if (searchResult.stat === "Ok") {
                var tokenArray = searchResult.values.map((scrip) => {
                    console.log("token", scrip)
                    return scrip.token
                })
            }
            const scripInfoPromises = await tokenArray.map((token) => {
                return this.shoonyaApi.get_security_info(exchange, token)
            });
            const scripInfo = await Promise.all(scripInfoPromises)
            return scripInfo;
        }
        catch (error) {
            console.log("error in get all expiry dates", error)
        }
    }

    async cancelAllOrders() {
        try {
            const orderHistory = await this.shoonyaApi.get_orderbook()
            console.log("orderHistory", orderHistory)
            if (orderHistory.stat !== "Ok") {
                return "No orders to cancel"
            }
            const orderPromises = orderHistory.map((order) => {
                return this.shoonyaApi.cancel_order(order.order_id)
            });
            const cancelOrders = await Promise.all(orderPromises)
            return cancelOrders;
        }
        catch (error) {
            console.error("error in cancel all orders", error)
        }

    }

    async clearAllPositions() {
        try {
            const positionHistory = await this.shoonyaApi.get_positions()
            console.log("positionHistory", positionHistory)
            if (positionHistory.stat !== "Ok") {
                return "No positions to clear"
            }
            const positionPromises = positionHistory.map((position) => {
                const nextPosition = position.netqty > 0 ? "SELL" : "BUY"
                const price_type = position.netqty > 0 ? "MARKET" : "LIMIT"
                const price = 0
                const trigger_price = 0
                const retention = "IOC"
                return this.shoonyaApi.place_order(nextPosition, position.prd, position.exchange, position.tsym, position.netqty, 0, price_type, price, trigger_price, retention, "NO", "From KredKonnect by 💝")
            });
            const clearPositions = await Promise.all(positionPromises)
            return clearPositions;
        }
        catch (error) {
            console.error("error in clear all positions", error)
        }

    }

    async getMTM() {
        try {
            const mtm = await this.shoonyaApi.get_mtm()
            console.log("mtm", mtm)
            return mtm;
        }
        catch (error) {
            console.error("error in get mtm", error)
        }

    }

    async getTSLData() {
        return window.localStorage.getItem("TSL")
    }

    async updateTSLData(token, newTokenData) {
        const oldData = JSON.parse(window.localStorage.getItem("TSL"))
        const newData = {
            ...oldData,
            [token]: newTokenData
        }
        window.localStorage.setItem("TSL", JSON.stringify(newData))
        return newData
    }

    async searchScrip(searchText) {
        // This Method Uses The Shoonya WebApi to Search 
        const data = `jData={"uid":"${this.shoonyaApi.__accountid}","stext":"${searchText}"}&jKey=${this.shoonyaApi.__susertoken}`
        // console.log("data", data)
        var response = await fetch("https://trade.shoonya.com/NorenWClientWeb/SearchScrip", {
            method: "POST",
            body: data
        })
        response = await response.json()
        // console.log("response", response)

        return response
    }

    async autoStrike(symbol='Nifty 50') { 
        // function for autoStrike 
        // const symbol = document.querySelector("#auto-strike").value
        // console.log("auto strike symbol", symbol)
        var autoCe = "" , autoPe=""
        if (symbol==="Nifty 50") { 
          var ltp = await this.shoonyaApi.get_quotes("NSE","26000")
          ltp = Math.round(ltp?.data?.lp/100)*100
          console.log("ltp",ltp)
          const searchResult = await window.api.searchScrip(`Nifty ${ltp}`)
          console.log("search result",searchResult)
           autoPe = searchResult?.values[0]
           autoCe = searchResult?.values[1]
          console.log("pe and ce",autoPe)
          console.log("pe and ce",autoCe)

        }
        else if (symbol==="BankNifty") { 
            var ltp = await this.shoonyaApi.get_quotes("NSE","26009")
            ltp = Math.round(ltp?.data?.lp/100)*100
            console.log("ltp",ltp)
            const searchResult = await window.api.searchScrip(`Banknifty ${ltp}`)
            console.log("search result",searchResult)
             autoPe = searchResult?.values[0]
             autoCe = searchResult?.values[1]
            console.log("pe and ce",autoPe)
            console.log("pe and ce",autoCe)
        }
        else if (symbol==="FinNifty") { 
            var ltp = await this.shoonyaApi.get_quotes("NSE","26037")
            ltp = Math.round(ltp?.data?.lp/100)*100
            console.log("ltp",ltp)
            const searchResult = await window.api.searchScrip(`FinNifty ${ltp}`)
            console.log("search result",searchResult)
             autoPe = searchResult?.values[0]
             autoCe = searchResult?.values[1]
            console.log("pe and ce",autoPe)
            console.log("pe and ce",autoCe)
        }
        window.ws.sendQuote(autoCe.exch,autoCe.token)
        window.ws.sendQuote(autoPe.exch,autoPe.token)
        return[ autoCe,autoPe]
    }
}



class ShoonyaWebSocket {
    constructor(uid, susertoken, callBackFunctions) {
        this.uid = uid;
        this.susertoken = susertoken;
        this.websocket = new WebSocket("wss://api.shoonya.com/NorenWSTP/");

        // Store reference to `this` for use inside event handlers
        const self = this;
        this.websocket.onmessage = (event) => {
            const messageData = JSON.parse(event.data);
            // Call the provided callback function with the parsed message data
            callBackFunctions.onmessage(messageData);// Call the provided callback function with event argument
        };
        this.websocket.onerror =
            (event) => {
                callBackFunctions.onerror(event); // Call the provided callback function with event argument
            };
        this.websocket.onclose = (event) => {
            callBackFunctions.onclose(event); // Call the provided callback function with event argument
        };
        this.websocket.onopen = () => {
            // Call the provided callback function with the parsed message data
            let values = { "t": "c" };
            values["uid"] = this.uid;
            values["actid"] = this.uid;
            values["susertoken"] = this.susertoken;
            values["source"] = "API";
            console.log(JSON.stringify(values));
            this.websocket.send(JSON.stringify(values));
            this.sendQuote("NSE", "26000");
            callBackFunctions.onopen();// Call the provided callback function with event argument
        };;
    }

    sendQuote(exchange, token) {
        try        {this.websocket.send(JSON.stringify({ "t": "t", "k": `${exchange}|${token}` }));}
        catch(error){ console.error("error in send quote",error)}
    }
}

async function main() {
    const shoonyaWrapper = new ShoonyaWrapper();
    try {
        // console.log("Wrapper", shoonyaWrapper);
        const res = await shoonyaWrapper.login({
            'userid': 'FA76640',
            'password': 'Trade@5'
        });
        // console.log(" login response", res)
        // console.log("username ", shoonyaWrapper.shoonyaApi.__susertoken)
        // const searchScrip = await shoonyaWrapper.getAllExpiryDates("NFO", "BANKNIFTY")
        // await shoonyaWrapper.cancelAllOrders()
        // const trades = await shoonyaWrapper.shoonyaApi.get_orderbook()
        // console.log("trades", trades)
        // const funds = await shoonyaWrapper.shoonyaApi.get_limits()
        // console.log("funds", funds)
        // await shoonyaWrapper.clearAllPositions()
        // console.log("search scrip response", searchScrip)
        const search = await shoonyaWrapper.searchScrip("BANKNIFTY")
        // console.log("search", search)
    }
    catch (error) {
        console.error("error in main", error)
    }
    // const info = await shoonyaWrapper.shoonyaApi.get_security_info("NFO", "NIFTY")
    // console.log("info", info)

}

// main()
export { ShoonyaWrapper, ShoonyaWebSocket }

// export default ShoonyaWrapper;