import React from 'react'
import { Route, Routes } from 'react-router'
import LoginPage from '../pages/loginPage'
import DashboardPage from '../pages/DashboardPage'
import OrderBook from '../components/OrderBook'
import TradeBook from '../components/TradeBook'

const AppRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/order" element={<OrderBook />} />
      <Route path="/trade" element={<TradeBook />} />
    </Routes>
  )
}

export default AppRouters