import React, { useContext } from 'react'
import { ThemeContext } from "../context/ThemeContext"
import { IoSunnySharp, IoMoonOutline } from "react-icons/io5";

const ThemeToggleBtn = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const handleThemeClick = (e) => {
    setTheme(!theme);
  };

  return (
    <label className="relative mt-4 inline-flex items-center mb-3 cursor-pointer bg-skin-togglebg border border-toggleBorder rounded-full" >
      <input
        type="checkbox"
        value=""
        checked={theme}
        className="sr-only peer"
        onChange={handleThemeClick}
      />

      <div
        // onClick={handleThemeClick}
        className="p-2 py-3 w-14 h-6 bg-skin-cardhead peer-focus:outline-none pt-3 rounded-full  dark:bg-gray-700 peer-checked:after:translate-x-[2rem]  after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-transparent-600"
      >
        <IoMoonOutline className="fas fa-moon text-skin-moon absolute top-1/2 left-3 transform  -translate-x-1/2 -translate-y-1/2" />

        <IoSunnySharp className="fas fa-sun text-skin-sun absolute top-1/2 -right-1 transform -translate-y-1/2 -translate-x-1/2" />
      </div>
    </label>
  )
}

export default ThemeToggleBtn