export const Exchanges = [
  "NSE",
  "NFO",
  "BSE",
  "CDS",
];

export const expiryDates = [
  new Date("2024-02-24").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-03-15").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-04-20").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-05-18").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-06-21").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-07-19").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-08-16").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-09-20").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-10-18").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
  new Date("2024-11-15").toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }),
];

export const CallstrikePrices = [
  35000, 35500, 36000, 36500, 37000, 37500, 38000, 38500, 39000, 39500,
];

export const putStrikePrices = [
  35200, 35750, 36250, 36750, 37250, 37750, 38250, 38750, 39250, 39750,
];

export const quantity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const orderTypes = [
  "MKT",
  "LMT",
  // "Stop Order",
  // "Stop-Limit Order",
  // "Trailing Stop Order",
  // "Bracket Order",
];

export const menuItems = [
  {
    title: "Positions",
    id: 1,
  },
  {
    title: "Order book",
    id: 2,
  },
  {
    title: "Trade book",
    id: 3,
  },
  {
    title: "Funds",
    id: 5,
  },
  {
    title: "Refresh Data",
    id: 6,
  },
];

export const positionTableData = [
  {
    Symbol: 'BANKNIFTY2351843600CE',
    Name: '-',
    Side: '',
    'Net Qty': 0,
    'Avg Price': 0.00,
    LTP: 500.5,
    SL: '-',
    'R. P&L': 6684.50,
    'UR. P&L': 0,
    'P&L': 6684.5,
    Action: 'Buy',
    'Buy Qty': 725,
    'Buy Price': 430.06,
    'Sell Price': 439.28,
    'Sell Qty': 725,
    Product: 'MARGIN'
  },
  {
    Symbol: 'BANKNIFTY2351843800CE',
    Name: '-',
    Side: '',
    'Net Qty': 0,
    'Avg Price': 0.00,
    LTP: 356.15,
    SL: '-',
    'R. P&L': -413.00,
    'UR. P&L': 0,
    'P&L': -413,
    Action: 'Buy',
    'Buy Qty': 100,
    'Buy Price': 395.89,
    'Sell Price': 391.76,
    'Sell Qty': 100,
    Product: 'MARGIN'
  },
  {
    Symbol: 'BANKNIFTY2351843900CE',
    Name: '-',
    Side: '',
    'Net Qty': 0,
    'Avg Price': 0.00,
    LTP: 292.05,
    SL: '-',
    'R. P&L': 137.25,
    'UR. P&L': 0,
    'P&L': 137.25,
    Action: 'Buy',
    'Buy Qty': 75,
    'Buy Price': 352.77,
    'Sell Price': 354.60,
    'Sell Qty': 75,
    Product: 'MARGIN'
  },
  {
    Symbol: 'BANKNIFTY2351844000PE',
    Name: '-',
    Side: '',
    'Net Qty': 0,
    'Avg Price': 0.00,
    LTP: 258.7,
    SL: '-',
    'R. P&L': -3206.00,
    'UR. P&L': 0,
    'P&L': -3206,
    Action: 'Buy',
    'Buy Qty': 200,
    'Buy Price': 397.69,
    'Sell Price': 381.66,
    'Sell Qty': 200,
    Product: 'MARGIN'
  }
];