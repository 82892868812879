import React from 'react';

const TradeBook = ({ tradeHistory }) => {
  return (
    <div className="trade-history">
      <h2>Trade History</h2>
      <ul>
        {tradeHistory.map((trade, index) => (
          <li key={index}>{trade.price} - {trade.amount}</li>
        ))}
      </ul>
    </div>
  );
};

export default TradeBook;
