import React from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';

const OrderBook = ({ buyOrders, sellOrders }) => {
    buyOrders = [
        { price: 12000, amount: 1.5, total: 18000 },
        // More buy orders
    ];

    sellOrders = [
        { price: 12100, amount: 2, total: 24200 },
        // More sell orders
    ];
    return (
        <div className="order-book">
            <h2 className="text-white mb-4">Order Book</h2>
            <MDBTable className="text-white" responsive hover dark>
                <MDBTableHead>
                    <tr>
                        <th>Price</th>
                        <th>Amount</th>
                        <th>Total</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    <tr>
                        <td colSpan="3" className="text-center">Buy Orders</td>
                    </tr>
                    {buyOrders.map((order, index) => (
                        <tr key={index}>
                            <td>{order.price}</td>
                            <td>{order.amount}</td>
                            <td>{order.total}</td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="3" className="text-center">Sell Orders</td>
                    </tr>
                    {sellOrders.map((order, index) => (
                        <tr key={index}>
                            <td>{order.price}</td>
                            <td>{order.amount}</td>
                            <td>{order.total}</td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </div>
    );
};

export default OrderBook;
