// TableComponent.js
import React, { useEffect, useState } from 'react';
import { positionTableData } from '../../constant/constant';

const TableComponent = ({ TableData, showHeaders }) => {
  showHeaders = new Set(showHeaders)
  // console.log("Table Data Object and filters", TableData, showHeaders)
  return (
    (TableData?.length > 0) ?
      <div className="overflow-x-auto overflow-y-auto h-[350px]  rounded-md">
        <table className="table-auto min-w-full border-collapse border border-tableHeadingBorder">
          <thead className='sticky'>
            <tr className="">
              {Object.keys(TableData[0]).map((key, index) => (
                showHeaders.has(key) && <th key={index} className="p-3 text-nowrap text-[0.9rem] font-medium  text-center capitalize text-skin-textColor bg-skin-bgColor border border-tableHeadingBorder" > {key}</th>
                // <th key={index} className="px-4 py-2 w-fullF text-nowrap text-[0.9rem] font-medium  text-center" > {key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TableData.map((row, rowIndex) => (
              // showHeaders.has(Object.keys(row)[0]) &&
              < tr key={rowIndex} >
                {
                  Object.keys(row).map((key, colIndex) => (
                    showHeaders.has(key) && <td key={colIndex} className="border px-4 py-2 font-normal text-center text-[13px] border-tableHeadingBorder">{TableData[rowIndex][key]}</td>
                  ))
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div > : <div className='text-skin-textColor py-8 text-center'> No Data Found </div>
  );
};

export default TableComponent;
