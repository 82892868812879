
import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import MobileCardComponent from "./MobileCardComponent";

export const FundsComponent = () => {
	const [data, setData] = useState([]);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 448)

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 448);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isSmallScreen]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await window?.api?.shoonyaApi.get_limits();
				// console.log("response from funds book api", response);
				if (response) {
					setData([response.data]);
				}
			} catch (error) {
				console.log("error in fetching funds book data", error);
			}
		};
		fetchData();
	}, []);

	return (
		<div className="bg-skin-cardbgColor text-skin-textColor rounded-b-md md:px-6 py-3 mb-4
		mt-4 ">
			{
				isSmallScreen ? (
					<MobileCardComponent title="funds" data={data} showHeaders={['cash', 'marginused', 'trantype', 'rejreason']} /> 
				) : (
					<TableComponent TableData={data} showHeaders={['cash', 'marginused', 'trantype', 'rejreason']} />
				)
			}


		</div>
	);
};
