import React from 'react'

const MobileCardComponent = ({ title, data, showHeaders }) => {
  showHeaders = new Set(showHeaders)

  return (
    <>
      {
        (data?.length > 0) ?
          <>
            <div className="bg-skin-cardbgColor text-skin-textColor rounded py-3 md:px-4 flex flex-col items-center justify-between gap-4">
              <div className="w-full flex items-center justify-between">
                {Object.keys(data[0]).map((key, index) => (
                  showHeaders?.has(key) && <span className="">{key}</span>
                ))}
                {data.map((row, rowIndex) => (
                  <span key={rowIndex} >
                    {
                      Object.keys(row).map((key, colIndex) => (
                        showHeaders?.has(key) && <span className="w-[30%]">{data[rowIndex][key]}</span>
                      ))
                    }
                  </span>
                ))}

              </div>
            </div>
          </>
          : <div className='text-skin-textColor py-8 text-center'> No Data Found </div>
      }
    </>
  )
}

export default MobileCardComponent