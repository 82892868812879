const Api = require("./lib/RestApi");
const TOTP = require("totp-generator");

let { authparams } = require("./cred");
const { on } = require("ws");

const api = new Api({});
module.exports = api

async function main() {
    try {
        authparams.twoFA = TOTP.TOTP.generate(authparams.twoFA).otp
        const res = await api.login(authparams)
        // console.log("username ", api.__username)
        // console.log('Reply: ', res);
        // console.log("api", api.__username)

        const callbacks = {
            socket_open: () => {
                console.log("socket open"); api.subscribe('NSE|26000'); console.log("subscribed")
            },
            socket_close: () => {
                console.log("socket close")
            },
            socket_error: () => { console.log("socket error") },
            quote: (msg) => { console.log("quote", msg) },
            order: (msg) => { console.log("order", msg) },
        }

        api.start_websocket(callbacks)

        // await api.start_websocket((msg) => { api.subscribe(['NSE|21000', 'BSE|522032']); console.log("websocket message", msg);  })


        // const searchScrip = await api.get_security_info('NFO', '35443')
        // console.log("search scrip response", searchScrip)
        // const optionChain = await api.get_option_chain('NFO', 'NIFTY', '21000', 10)
        // console.log("option chain response", optionChain)
    } catch (error) {
        console.error(error);
    }

}

// main();
